import { Container, Grid, Paper, Box, Typography, Button } from "@mui/material";
import FounderImage from "../images/founder.jpg";

const Founder = () => {
  return (
    <Box sx={{ backgroundColor: "#f0f0f0", py: 6 }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ width: "60%", mx: "auto" }}>
              <Box
                component="img"
                src={FounderImage}
                alt="Founder"
                sx={{
                  width: "100%",
                  //   borderRadius: 5,
                  height: { xs: "15em", md: "25em" },
                }}
              />
            </Paper>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: "flex", px: "2em", textAlign: "left" }}
          >
            <Box>
              <Typography variant="h6" sx={{ fontSize: "15px" }}>
                <strong>Our Founder</strong>
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "green",
                  fontWeight: "bold",
                  py: 3,
                  fontSize: "20px",
                }}
              >
                Dr.Thadivalasa Deva Raju <sub>M.A,M.B.A,LLB.,</sub>{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "justify",
                  margin: "0 0 10px",
                  lineHeight: "1.3",
                  textIndent: "2em",
                  // color: "#757575",
                  fontStyle: "italic",
                  py: 1,
                }}
              >
                <strong>SRI KAMAKSHI CARE HOSPITAL,</strong> Chirala was started
                on 2013 Feb 14th by the Managing Director{" "}
                <strong>
                  Dr.Thadivalasa Deva Raju <sub>M.A,M.B.A,LLB.,</sub>{" "}
                </strong>
                He has a vision of providing high-end quality healthcare for the
                people in and surrounding CHIRALA, PRAKASAM Dist now its BAPATLA
                District. His mission is to bring healthcare of BEST standards
                within the reach of every individual.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "justify",
                  margin: "0 0 10px",
                  lineHeight: "1.3",
                  textIndent: "2em",
                  // color: "#757575",
                  fontStyle: "italic",
                }}
              >
                He was always committed to building a multi-specialty care
                hospital, which has indeed become one of the cornerstones of the
                healthcare infrastructure in Chirala, setting new benchmarks in
                quality standards for healthcare delivery.
              </Typography>
              {/* <Button
                  variant="contained"
                  target="_blank"
                  href="https://www.facebook.com/deva.thadivalasa"
                  sx={{ display: "block" }}
                > */}
              <Button
                variant="contained"
                target="_blank"
                color="primary"
                href="https://www.facebook.com/deva.thadivalasa"
                >
                Get In Touch
              </Button>

              {/* Get In Touch
                </Button> */}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Founder;
