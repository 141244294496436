import { Typography, Grid, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, Modal } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Slider from "react-slick";
import { useState } from "react";

const NewsCoverageItems = [
  {
    id: 1,
    newsimage: require("../images/media/media01.jpg"),
    newsname: "State Times- 11-Apr-2023",
  },
  {
    id: 2,
    newsimage: require("../images/media/media02.jpg"),
    newsname: "Vartha Prapacham- 01-Aug-2022",
  },

  {
    id: 3,
    newsimage: require("../images/media/media03.jpg"),
    newsname: "Prajapalana-11-July-2022",
  },
  {
    id: 4,
    newsimage: require("../images/media/media04.jpg"),
    newsname: "Pen Power-11-July-2022",
  },
  {
    id: 5,
    newsimage: require("../images/media/media05.jpg"),
    newsname: "Udaya Akshrayam-28-May-2022",
  },
  {
    id: 6,
    newsimage: require("../images/media/media06.jpg"),
    newsname: "State Times- 28-May-2022",
  },
  {
    id: 7,
    newsimage: require("../images/media/media16.jpg"),
    newsname: "State Times- 25-March-2023",
  },
  {
    id: 8,
    newsimage: require("../images/media/media08.jpg"),
    newsname: "Sri Chakra-24-Apr-2023",
  },
  {
    id: 9,
    newsimage: require("../images/media/media09.jpg"),
    newsname: "Pen Power-24-Apr-2023",
  },
  {
    id: 10,
    newsimage: require("../images/media/media10.jpg"),
    newsname: "State Times 11-Apr-2023",
  },
  {
    id: 11,
    newsimage: require("../images/media/media12.jpg"),
    newsname: "Andhra Voice- 16-June-2019",
  },
  {
    id: 12,
    newsimage: require("../images/media/media13.jpg"),
    newsname: "State Times 22-March-203",
  },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const NewsModal = ({ open, handleClose, images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ ...style, width: 600 }}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index}`} style={{ width: '100%' }} />
            </div>
          ))}
        </Slider>
      </Box>
    </Modal>
  );
};

const NewsCoverage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleCardClick = (image) => {
    setSelectedImages([image]); // Assuming each card has only one image
    setModalOpen(true);
  };

  return (
    <Box sx={{ backgroundColor: "rgb(240 240 240)", width: "100%" }}>
      <Typography variant="h3" textAlign="center" pt={3} color="green" fontSize="2em" fontFamily="inherit">
        <strong>
          News Clippings |{" "}
          <RouterLink to="/gallery" style={{ color: "green" }}>
            Gallery
          </RouterLink>{" "}
        </strong>
      </Typography>

      <Grid container spacing={2} sx={{ px: 10, py: 5, margin: "auto" }}>
        {NewsCoverageItems.map((item) => (
          <Grid item xs={12} sm={6} md={6} lg={3} textAlign="center" key={item.id}>
            <Card sx={{ maxWidth: 380, height: "100%", borderRadius: "5%" }}>
              <CardActionArea onClick={() => handleCardClick(item.newsimage)}>
                <CardMedia
                  component="img"
                  height="200"
                  image={item.newsimage}
                  alt={item.newsname}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" color="green" wordWrap="break-word" fontSize="1.4rem">
                    {item.newsname}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <NewsModal open={modalOpen} handleClose={() => setModalOpen(false)} images={selectedImages} />
    </Box>
  );
};

export default NewsCoverage;