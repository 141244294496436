import { Box } from "@mui/system";
import { Typography, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const GalleryCardItem = [
  {
    id: 1,
    pagename: "hospitalgallery",
    cardimage: require("../images/hospital.png"),
    cardname: "Hospital Gallery",
  },
  {
    id: 2,
    pagename: "eventsgallery",
    cardimage: require("../images/event.png"),
    cardname: "Events Gallery",
  },
  {
    id: 3,
    pagename: "newscoverage",
    cardimage: require("../images/media.png"),
    cardname: "News Coverage",
  },

  {
    id: 4,
    pagename: "vediogallery",
    cardimage: require("../images/vedio.png"),
    cardname: "Vedio Gallery",
  },
  {
    id: 5,
    pagename: "healthtips",
    cardimage: require("../images/healthtips.png"),
    cardname: "Health Tips",
  },
];

const Gallery = () => {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(240 240 240)",
        width: "100%",
      }}
    >
      <Typography
        varient="h3"
        textAlign={"center"}
        pt={5}
        color={"green"}
        fontSize={"2em"}
        fontFamily={"inherit"}
      >
        <strong>Gallery</strong>
      </Typography>

      <Grid container spacing={1} sx={{ px: 5, py: 8 }}>
        {GalleryCardItem.map((Gallery) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={2}
            sx={{
              margin: "auto",
              paddingBottom: { xs: 2, sm: 2 }, 
            }}
            textAlign={"center"}
            margin={"auto"}
          >
            <Card sx={{ maxWidth: 345, borderRadius: "10%" }}>
              <RouterLink to={`/gallery/${Gallery.pagename}`}>
                {" "}
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={Gallery.cardimage}
                    alt={Gallery.cardname}
                    sx={{ width: "8rem", pt: "1rem", margin: "auto" }}
                  />
                  <u />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      color={"green"}
                      wordWrap={"break-word"}
                      fontSize={"1.4rem"}
                    >
                      {Gallery.cardname}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </RouterLink>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Gallery;
