import "./header1.styles.css";
import Sliders from "./sliders";
import About from "./about/about";
import Departments from "./department/department";
import Emergency from "./emergency/emergency";
import Contact from "./contact";
import Founder from "./founder";
import MaterialUICarousel from "./carousel";
import Gallery from "./gallery";
import DepartmentName from "./appoinment";
import Mous from "./ourmous";
import AddressMap from "./map";
// import Footer from "./footer";
// import ResponsiveAppBar from "./appbar";
// import Topbar from "./topbar";
const Header = () => {
  return (
    <div >
      {/* <Topbar/>
      <ResponsiveAppBar/> */}
      < Sliders />
      < About />
      <Departments />
      <Emergency />
      <Contact />
      <Founder />
      < MaterialUICarousel />
      <Gallery />
      <DepartmentName />
      <Mous/>
      <AddressMap/>
      {/* <Footer/> */}
    </div>
  );
};

export default Header;
