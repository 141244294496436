import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import marqueeimage from "../images/new.gif";
import { Grid } from "@mui/material";
import "./sliders.styles.css";
import Marquee from "react-fast-marquee";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    imgPath: require("../images/sliders/slider1.jpg"),
  },
  {
    imgPath: require("../images/sliders/slider2.jpg"),
  },
  {
    imgPath: require("../images/sliders/slider3.jpg"),
  },
  {
    imgPath: require("../images/sliders/slider4.jpg"),
  },
  {
    imgPath: require("../images/sliders/slider5.jpg"),
  },
  {
    imgPath: require("../images/sliders/slider6.jpg"),
  },
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Grid>
      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          width: "100%",
          paddingTop: "1rem",
        }}
      >
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={{
                    height: 310,
                    display: "block",
                    overflow: "hidden",
                    width: "100%",
                    px: "2rem",
                  }}
                  src={step.imgPath}
                  alt={step.label}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        {/* Back button */}
        <Button
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ position: "absolute", top: "50%", left: 16, zIndex: 1 }}
        >
          <KeyboardArrowLeft />
        </Button>
        {/* Next button */}
        <Button
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{
            position: "absolute",
            top: "50%",
            right: 16,
            zIndex: 1,
            px: "1rem",
          }}
        >
          <KeyboardArrowRight />
        </Button>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          sx={{
            position: "absolute",
            bottom: 0,
            left: "2rem",
            right: "2rem",
            width: "auto", // Adjust the width to match your design
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            zIndex: 1, // Ensure it's above the images
          }}
          nextButton={<div />} // Empty divs for hiding default buttons
          backButton={<div />}
        />{" "}
      </Box>

      <Box sx={{ px: "2rem" }}>
        <Marquee speed={'120'} pauseOnHover={'false'}
          style={{
            backgroundColor: "#e5f9db",
            height: "3rem",
            // width: "96.5%",
            margin: "auto",
            textAlign: "center",
            // paddingTop: "0.8rem",
            px: "2rem",
          }}
        >
          <div className="marquee">
            <div
              className="marquee-content"
              style={{
                backgroundColor: "#e5f9db",
                height: "3rem",
                // width: "96.5%",
                margin: "auto",
                textAlign: "center",
                paddingTop: "0.8rem",
              }}
            >
              <img
                src={marqueeimage}
                alt="Marquee"
                style={{ display: "inline-block" }}
              />
              <b style={{ paddingLeft: "1rem" }}>
                Welcome to Sri Kamakshi Care Hospital, Chirala
              </b>
            </div>
          </div>
        </Marquee>
      </Box>
    </Grid>
  );
}

export default SwipeableTextMobileStepper;
