import { Grid, Box, Typography, Container } from "@mui/material";
import arogyasreeimage from "../images/arogya2.jpg.png";

const Arogyasree = () => {
  return (
    <Box sx={{ backgroundColor: "#f0f0f0" }}>
      <Container>
        <Grid
          container
          spacing={3}
          sx={{ pt: 8, pb: 8, backgroundColor: "#f0f0f0" }}
        >
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={arogyasreeimage}
              alt="Arogya sree"
              sx={{
                width: "100%",
                borderRadius: 5,
                height: { xs: "15em", md: "25em" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ px: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "left",
                  color: "green",
                  pl: 8,
                  pb: 3,
                  // fontWeight: "bold",
                }}
              >
                WHAT WE’RE OFFERING
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  textAlign: "left",
                  pl: 8,
                  pb: 3,
                  fontWeight: "600",
                  fontSize: "2em",
                }}
              >
                Aarogya SRI Services
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  // textAlign: "left",
                  margin: "0 0 10px",
                  lineHeight: "1.3",
                  textIndent: "2em",
                  fontFamily: "'Poppins',sans-serif",
                  fontWeight: 400,
                  color: "#757575",
                  fontSize: "1.2em",
                  pl: 8,
                  textAlign: "justify"
                }}
              >
                Sri Kamakshi Hospital provides free health services in the
                following departments: Orthopedics, Polytrauma, General Surgery,
                Laparoscopic Surgery, ENT Surgery, Urology Surgery, and Dialysis
                Services.
              </Typography>{" "}
              <Typography
                variant="body1"
                sx={{
                  // textAlign: "left",
                  margin: "0 0 10px",
                  lineHeight: "1.3",
                  textIndent: "2em",
                  fontFamily: "'Poppins',sans-serif",
                  fontWeight: 400,
                  color: "#757575",
                  fontSize: "1.2em",
                  pl: 8,
                  textAlign: "justify"
                }}
              >
                In our hospital, we accept White Card holders, EHS Card holders,
                and Working Journalists Health Card holders to provide cashless
                services under the Aarogya Sri scheme.
              </Typography>{" "}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Arogyasree;
