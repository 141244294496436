import { Typography, Grid, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";


const hospitalgalleryitems = [
  {
    id: 1,
    galleryimage: require("../images/hospitalgallery/hosgallery1.jpg"),
    imagename: "Hospital",
  },
  {
    id: 2,
    galleryimage: require("../images/hospitalgallery/hosgallery2.jpg"),
    imagename: "Our Team",
  },
  {
    id: 3,
    galleryimage: require("../images/hospitalgallery/hosgallery3.jpg"),
    imagename: "Reception",
  },
  {
    id: 4,
    galleryimage: require("../images/hospitalgallery/hosgallery4.jpg"),
    imagename: "Waiting Area",
  },
  {
    id: 5,
    galleryimage: require("../images/hospitalgallery/hosgallery5.jpg"),
    imagename: "ICU",
  },
  {
    id: 6,
    galleryimage: require("../images/hospitalgallery/hosgallery6.jpg"),
    imagename: "Operation Theater",
  },
];

const HospitalGallery = () => {
  return (
    <Box
    sx={{
      backgroundColor: "rgb(240 240 240)",
      width: "100%",
    }}
  >
    <Typography
      variant="h3"
      textAlign="center"
      pt={3}
      color="green"
      fontSize="2em"
      fontFamily="inherit"
    >
      <strong>Hospital Gallery | <RouterLink to="/gallery" style={{color: 'green'}}>Gallery</RouterLink> </strong>
    </Typography>

    <Grid container spacing={2} sx={{ px: 10, py: 5, margin: 'auto' }}>
      {hospitalgalleryitems.map((item) => (
        <Grid item xs={12} sm={6} lg={4} textAlign="center" pb={'5'}>
          <Card sx={{ maxWidth: 380, height: "100%", borderRadius: "5%" }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={item.galleryimage}
                alt={item.imagename}
                // sx={{ width: "8rem", pt: "1rem", margin: "auto" }}
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  color="green"
                  wordWrap="break-word"
                  fontSize="1.4rem"
                >
                  {item.imagename}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
    </Box>
  );
};

export default HospitalGallery;
