import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  MenuItem
} from "@mui/material";
import contactimage from "../images/contact.jpg";
import { useState } from "react";

const Contact = () => {
  const [dept, setDept] = useState([]);
  // const [date, setDate] = useState(null);
  const deptInfo = [
    "General medicine",
    "Critical Care",
    "Orthopedic",
    "opthomology",
    "Ayurvedic",
    "Cardiology",
  ];

  return (
    <Container>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          color: "green",
          fontWeight: "bold",
          pt: 5,
        }}
      >
        CONTACT US
      </Typography>
      <Grid container spacing={3} sx={{ pt: 3, pb: 8 }}>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={contactimage}
            alt="Contact image"
            sx={{
              width: "100%",
              borderRadius: 3,
              height: { xs: "15em", md: "25em" },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ px: 3 }}>
            <form>
              <TextField
                fullWidth
                sx={{ mb: 1 }}
                type="text"
                label="Full Name"
                variant="outlined"
                required
              />
              <TextField
                fullWidth
                sx={{ mb: 1 }}
                type="text"
                label="Phone No"
                variant="outlined"
              />
              <TextField
                fullWidth
                sx={{ mb: 1 }}
                type="text"
                label="Email"
                variant="outlined"
              />
              {/* <TextField fullWidth sx={{ mb: 1 }} type="text" label="Department" variant="outlined" /> */}
              <TextField
                label="Select Department"
                variant="outlined"
                value={dept}
                onChange={(e) => setDept(e.target.value)}
                select
                SelectProps={{ multiple: true }}
                fullWidth
                sx={{ mb: 1 }}

              >
                {deptInfo.map((dept) => (
                  <MenuItem value={dept} key={dept}>
                    {dept}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                fullWidth
                sx={{ mb: 4 }}
                type="text"
                label="Message"
                variant="outlined"
              />
              <Button variant="contained" color="success" fullWidth>
                Submit
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Contact;
