import React from "react";
import { Box, Grid, Typography, Card, CardMedia } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const VideoGallery = () => {
  const videos = [
    { src: require("../images/vedios/vedio1.mp4"), title: "Gastro" },
    { src: require("../images/vedios/vedio2.mp4"), title: "Cardiology" },
    { src: require("../images/vedios/vedio3.mp4"), title: "Arogya Sree" },
    { src: require("../images/vedios/vedio4.mp4"), title: "News" },
  ];

  return (
    <Box sx={{ backgroundColor: "rgb(240 240 240)", width: "100%" }}>
      <Typography
        variant="h3"
        textAlign="center"
        pt={3}
        color="green"
        fontSize="2em"
        fontFamily="inherit"
      >
        <strong>
          Video Gallery |{" "}
          <RouterLink to="/gallery" style={{ color: "green" }}>
            Gallery
          </RouterLink>
        </strong>
      </Typography>
      <Grid container spacing={2} sx={{ px: 10, py: 5, margin: "auto" }}>
        {videos.map((video, index) => (
          <Grid
            item
            xs={12}
            md={3}
            key={index}
            sx={{ mb: 2, textAlign: "center" }}
          >
            <Card
              sx={{
                maxWidth: "17em",
                height: "20em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <CardMedia component="video" controls>
                <source
                  src={video.src}
                  type="video/mp4"
                  style={{ width: "15em", alignItems: "center", display: "inline-block", height: "auto"}}
                />
              </CardMedia>{" "}
              <Typography sx={{ fontSize: 20, mt: 1, mb: 1 }}>
                {video.title}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default VideoGallery;
