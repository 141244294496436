import { Grid, Typography, Box } from "@mui/material";
import React from "react";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import InfoIcon from "@mui/icons-material/Info";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Divider } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const Footer = () => {
  return (
    <Grid
      container
      spacing={2}
      sx={{ px: 15, py: 5, backgroundColor: "#e5f9db" }}

      // justifyContent="center"
      // alignItems="center"
    >
      <Grid item xs={12} md={4} lg={4} sx={{ textAlign: "left" }}>
          <Typography
            variant="h6"
            fontSize="1.8em"
            fontFamily="inherit"
            sx={{ pb: "10px" }}
          >
            <strong>Contact Info</strong>
          </Typography>
          <a href="https://www.google.com/maps/place/Sri+Kamakshi+Care+Hospital/@15.82886,80.359214,92m/data=!3m1!1e3!4m6!3m5!1s0x3a4a441924995d85:0x328c14de7d3c1e1a!8m2!3d15.82886!4d80.3592135!16s%2Fg%2F11r48qkbkt?hl=en&entry=ttu" target="_blank" rel="noreferrer">

          <Typography
            variant="body1"
            color="#454545"
            fontFamily="Poppins, sans-serif"
            fontSize="1.2em"
            sx={{ pb: "5px", "&:hover": { color: "#4267b2" } }}
          >
            Sri Kamakshi Care Hospital
          </Typography>
          <Typography
            variant="body1"
            color="#454545"
            fontFamily="Poppins, sans-serif"
            fontSize="1.2em"
            sx={{ pb: "5px", "&:hover": { color: "#4267b2" } }}
          >
            Near Suresh Mahal, Church Road
          </Typography>
          <Typography
            variant="body1"
            color="#454545"
            fontFamily="Poppins, sans-serif"
            fontSize="1.2em"
            sx={{ pb: "10px", "&:hover": { color: "#4267b2" } }}
          >
            Chirala
          </Typography>
        </a>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pb: "10px",
            fontSize: "1.2em",
            fontFamily: "Poppins, sans-serif",
            "&:hover": { color: "#4267b2" },
          }}
        >
          <a href="tel:+919000808198" style={{ color: "#454545" }}>
            <ContactPhoneIcon sx={{ mr: "5px", color: '#8d6e63' }} />
            {" "}{"91 9000808198"}
          </a>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pb: "10px",
            fontWeight: "normal",
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.2em",
            "&:hover": { color: "#4267b2" },
          }}
        >
          <a
            href="mailto:srikamakshiweb@gmail.com?subject=Mail%20from%20Our%20Site"
            style={{ color: "#454545" }}
          >
            <MarkEmailReadIcon sx={{ mr: "5px",color: '#8d6e63' }} />
            {" "}srikamakshiweb@gmail.com
          </a>
          {/* srikamakshiweb@gmail.com */}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontFamily: "Poppins, sans-serif",
            fontSize: "1.2em",
            "&:hover": { color: "#4267b2" },
          }}
        >
          <a href="tel:+917897890737" style={{ color: "#454545" }}>
            <InfoIcon sx={{ mr: "5px", "&:hover": { color: "#4267b2" } , color: '#8d6e63'}} />{" "}
            Arogya Sri Info: +91 7897890737
          </a>{" "}
        </Box>
      </Grid>

      <Grid item xs={12} md={4} lg={4}>
        <Typography
          variant="h6"
          fontSize="1.8em"
          fontFamily="inherit"
          sx={{ pb: "10px" }}
        >
          <strong>Quick Links</strong>
        </Typography>
        <RouterLink to={"/"} style={{ color: "#454545" }}>
          <Typography
            variant="body1"
            fontFamily="sans-serif, poppins"
            sx={{ pb: "5px", pl: "5px", "&:hover": { color: "#4267b2" } }}
          >
            Home
          </Typography>
        </RouterLink>

        <Typography
          variant="body1"
          fontFamily="sans-serif, poppins"
          sx={{ pb: "5px", pl: "5px", "&:hover": { color: "#4267b2" } }}
        >
          <RouterLink to={"/about"} style={{ color: "#454545" }}>
            About
          </RouterLink>
        </Typography>
        <Typography
          variant="body1"
          fontFamily="sans-serif, poppins"
          sx={{ pb: "5px", pl: "5px", "&:hover": { color: "#4267b2" } }}
        >
          <RouterLink to={"/departments"} style={{ color: "#454545" }}>
            Department
          </RouterLink>
        </Typography>
        <Typography
          variant="body1"
          fontFamily="sans-serif, poppins"
          sx={{ pb: "5px", pl: "5px", "&:hover": { color: "#4267b2" } }}
        >
          <RouterLink to={"/gallery"} style={{ color: "#454545" }}>
            {" "}
            Gallery
          </RouterLink>
        </Typography>
        <Typography
          variant="body1"
          fontFamily="sans-serif, poppins"
          sx={{ pb: "5px", pl: "5px", "&:hover": { color: "#4267b2" } }}
        >
          <RouterLink to={"/contact"} style={{ color: "#454545" }}>
            {" "}
            Contacts
          </RouterLink>
        </Typography>
        <Typography
          variant="body1"
          fontFamily="sans-serif, poppins"
          sx={{ pb: "5px", pl: "5px", "&:hover": { color: "#4267b2" } }}
        >
          <RouterLink to={"/events"} style={{ color: "#454545" }}>
            {" "}
            Events
          </RouterLink>
        </Typography>
        <Typography
          variant="body1"
          fontFamily="sans-serif, poppins"
          sx={{ pb: "5px", pl: "5px", "&:hover": { color: "#4267b2" } }}
        >
          <RouterLink to={"./DepartmentName"} style={{ color: "#454545" }}>
            {" "}
            Make An Appointment
          </RouterLink>
        </Typography>
      </Grid>

      <Grid item xs={12} md={4} lg={4}>
        <Typography
          variant="h6"
          fontSize="1.8em"
          fontFamily="inherit"
          sx={{ pb: "10px" }}
        >
          <strong>Opening Hours</strong>
        </Typography>
        <Typography
          variant="body1"
          color="#454545"
          fontFamily="Poppins, sans-serif"
          fontSize="1.2em"
          sx={{ pb: "5px", "&:hover": { color: "#4267b2" } }}
        >
          Monday - Saturday 10:00 AM - 8:00 PM{" "}
        </Typography>
        <Typography
          variant="body1"
          color="#454545"
          fontFamily="Poppins, sans-serif"
          fontSize="1.2em"
          sx={{ pb: "5px", "&:hover": { color: "#4267b2" } }}
        >
          Sunday 10:00 AM - 1P.M{" "}
        </Typography>
        <Typography
          variant="body1"
          color="#454545"
          fontFamily="Poppins, sans-serif"
          fontSize="1.2em"
          sx={{ pb: "5px", "&:hover": { color: "#4267b2" } }}
        >
          All Emergency 24/7
        </Typography>
        <Box m="10px">
          <a
            href="https://www.facebook.com/people/Sri-Kamakshi-Care-Hospital/100065441741454/"
            style={{ color: "#454545" }}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon sx={{ mx: "5px", "&:hover": { color: "#4267b2" }, color: '#8d6e63' }} />{" "}
          </a>
          <a
            href="https://www.youtube.com/channel/UC4khiFFMzAxddzyL6uZ_aMg"
            style={{ color: "#454545" }}
            target="_blank"
            rel="noreferrer"
          >
            <YouTubeIcon sx={{ mx: "5px", "&:hover": { color: "#4267b2" } , color: '#8d6e63'}} />
          </a>
          <a
            href="https://twitter.com/skchospital?lang=en"
            style={{ color: "#454545" }}
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon sx={{ mx: "5px", "&:hover": { color: "#4267b2" }, color: '#8d6e63' }} />
          </a>
        </Box>
      </Grid>
      <Divider variant="inset" component="hr" />
    </Grid>
  );
};

export default Footer;
