import { Typography, Grid, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const EventGalleryItem = [
  {
    id: 1,
    eventimage: require("../images/eventgallery/eventgalleryimage1.jpg"),
    eventname: "Medical Camp",
  },
  {
    id: 2,
    eventimage: require("../images/eventgallery/eventgalleryimage2.jpg"),
    eventname: "Orientation Program",
  },
  {
    id: 3,
    eventimage: require("../images/eventgallery/eventgalleryimage3.jpg"),
    eventname: "ENT Camp",
  },
  {
    id: 4,
    eventimage: require("../images/eventgallery/eventgalleryimage4.jpg"),
    eventname: "Helping Hands",
  },
  {
    id: 5,
    eventimage: require("../images/eventgallery/eventgalleryimage5.jpg"),
    eventname: "Doctors Day Celebrations",
  },
  {
    id: 6,
    eventimage: require("../images/eventgallery/eventgalleryimage6.jpg"),
    eventname: "Health Checkup Camp",
  },
];

const EventsGallery = () => {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(240 240 240)",
        width: "100%",
      }}
    >
      <Typography
        variant="h3"
        textAlign="center"
        pt={3}
        color="green"
        fontSize="2em"
        fontFamily="inherit"
      >
        <strong>
          Event Gallery |{" "}
          <RouterLink to="/gallery" style={{ color: "green" }}>
            Gallery
          </RouterLink>{" "}
        </strong>
      </Typography>

      <Grid container spacing={2} sx={{ px: 10, py: 5, margin: "auto" }}>
        {EventGalleryItem.map((item) => (
          <Grid item xs={12} sm={6} lg={4} textAlign="center" pb={"5"}>
            <Card sx={{ maxWidth: 380, height: "100%", borderRadius: "5%" }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  image={item.eventimage}
                  alt={item.eventname}
                  // sx={{ width: "8rem", pt: "1rem", margin: "auto" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="green"
                    wordWrap="break-word"
                    fontSize="1.4rem"
                  >
                    {item.eventname}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EventsGallery;
