import {
  Grid,
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";

const DepartmentName = () => {
  const [dept, setDept] = useState([]);
  const [date, setDate] = useState(null);
  const deptInfo = [
    "General medicine",
    "Critical Care",
    "Orthopedic",
    "opthomology",
    "Ayurvedic",
    "Cardiology",
  ];

  return (
    <Grid container spacing={3} sx={{ py: 5, px: 10 }}>
      <Grid item xs={12} md={6} sx={{ px: 5, py: 2 }}>
        <Box
          component="img"
          src="https://www.srikamakshicarehospital.com/images/appointment.png"
          alt="appointment image"
          sx={{
            width: "85%",
            borderRadius: 5,
            height: "90%",
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography
          variant="h4"
          sx={{
            fontSize: "2em",
            letterSpacing: "-1px",
            fontWeight: 350,
            mb: 1,
            textAlign: "center",
          }}
        >
          <strong>Make an Appointment</strong>
        </Typography>
        <Box
          component="form"
          sx={{ px: 3, py: 1 }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                label="Full Name"
                variant="filled"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  variant="filled"
                  value={date}
                  onChange={(newValue) => setDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Select Department"
                variant="filled"
                value={dept}
                onChange={(e) => setDept(e.target.value)}
                select
                SelectProps={{ multiple: true }}
                fullWidth
              >
                {deptInfo.map((dept) => (
                  <MenuItem value={dept} key={dept}>
                    {dept}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="filled-basic"
                label="Phone Number"
                variant="filled"
                fullWidth
                // sx={{ pt: 1 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ pb: 1 }}>
            <TextField
              id="filled-basic"
              label="Email"
              variant="filled"
              fullWidth
              sx={{pt: 2}}
            />
          </Grid>
          <Grid item xs={12} sx={{ pt: 1, pb: 2 }}>
            <TextField
              label="Additional Message"
              variant="filled"
              fullWidth
              //   sx={{ p: 1 }}
            />
          </Grid>
          <Button
            variant="contained"
            color="success"
            fullWidth
            sx={{ px: "2" }}
          >
            Submit
          </Button>{" "}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DepartmentName;
