import { Box } from "@mui/system";
import { Typography, Grid } from "@mui/material";
// import Card from "@mui/material/Card";

const Mousnames = [
  {
    id: 1,
    mousimage: require("../images/mous/mou1.png"),
  },
  {
    id: 2,
    mousimage: require("../images/mous/mou2.png"),
  },
  {
    id: 3,
    mousimage: require("../images/mous/mou3.png"),
  },
  {
    id: 4,
    mousimage: require("../images/mous/mou4.png"),
  },
  {
    id: 5,
    mousimage: require("../images/mous/mou5.jpg"),
  },
  {
    id: 6,
    mousimage: require("../images/mous/mou6.png"),
  },
  {
    id: 7,
    mousimage: require("../images/mous/mou7.png"),
  },
];

const Mous = () => {
  return (
    <Box >
      <Typography
        varient="h3"
        textAlign={"center"}
        px={"5"}
        color={"green"}
        fontSize={"2em"}
        fontFamily={"inherit"}
      >
        <strong >
          Our <span className="red-text">MOU'S</span> With
        </strong>
      </Typography>
      <Grid container spacing={2} py={'20px'}>
        {Mousnames.map((Mous) => (
          <Grid
            item
            // xs={4} md={2} lg={2}
            key={Mousnames.key}
            sx={{ display: "flex", margin: "auto", py: "5" , pb: "5"}}
          >
            <Box
              component="img"
              src={Mous.mousimage}
              sx={{ width: "10rem", height: "4rem" ,objectFit: "contain"}}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Mous;
