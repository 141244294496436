import React from "react";
import { Grid, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { Box } from "@mui/system";

const testimonialData = [
  {
    text: "I have recently had knee surgery in Sri kamakshi care hospital. Though it is very rare we get hospitalization, I felt very comfortable in getting treated in this hospital as from Doctor to each and all staff everyone were supportive and helpful and handled me very well as I was not able to walk.",
    name: "Pavani",
    image: require("../images/1.png"),
    role: "CUSTOMER",
  },
  {
    text: "Recently we visited Kamakshi care Hospital. Doctor received our patient very well. The doctors given excellent treatment and the hospital is maintained well and taking all the precautions at all steps. The staff was very well trained. All departments' coordination was also very Good.",
    name: "Saritha",
    image: require("../images/2.png"),
    role: "CUSTOMER",
  },
  {
    text: "This is one of the best service emergency hospitals in Chirala. I took an accident case in my relationship. My friend gave the best service and operation in Arogya sre services. Best hospital in Chirala, and Dr. Gavini Lakshmi Narayana, a general surgeon, treatment was good.",
    name: "Pattem Manohar",
    image: require("../images/3.png"),
    role: "CUSTOMER",
  },
];

function MaterialUICarousel() {
  return (
    <Box
      sx={{
        backgroundColor: "#009889",
        width: "100%",
        // height: { xs: "80vh", sm: "70vh" },
      }}
    >
      <Carousel>
        {testimonialData.map((item, index) => (
          <Grid
            key={index}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ textAlign: "center", py: 3, height: "100%", px: 2 }}
          >
            <Typography
              variant="h3"
              color="white"
              fontSize={{ xs: "30px", md: "40px" }}
              gutterBottom
              sx={{ px: 2 }}
            >
              <strong>TESTIMONIALS</strong>
            </Typography>
            <Box
              sx={{
                borderRadius: "50%",
                bgcolor: "green",
                m: 1,
                border: "solid #00e5ff",
                width: "7rem",
                height: "7rem",
                pb: "5",
              }}
              component="img"
              src={item.image}
              alt="image"
            >
              {/* <PeopleIcon
                sx={{ width: "6rem", height: "6rem", color: "#36ffed", mb: 3 }}
              /> */}
            </Box>
            <Box
              sx={{
                maxWidth: "50rem",
                width: "100%", // ensure it doesn't overflow on small screens
                color: "white",
                textAlign: "justify",
                mb: 2,
                mt: 3,
              }}
            >
              <Typography
                variant="body1"
                fontSize={{ xs: "1em", md: "1.2em" }}
                sx={{ fontStyle: "italic", textIndent: "2em" }}
              >
                {item.text}
              </Typography>
              <Typography variant="h5" align="right" fontSize={"1em"} sx={{}}>
                {item.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Carousel>
    </Box>
  );
}
export default MaterialUICarousel;
