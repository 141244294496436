import { Typography, Grid, Box } from "@mui/material";
import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";


const HealthTipsItems = [
    {
        id: 1,
        image: require("../images/healthtips/pic1.jpeg"),
        
    },
    {
        id:2,
        image: require("../images/healthtips/pic2.jpeg")
    },
    {
        id:3,
        image: require("../images/healthtips/pic3.jpeg")
    },
    {
        id:4,
        image: require("../images/healthtips/pic4.jpeg")
    },
    {
        id: 5,
        image: require("../images/healthtips/pic5.jpeg")
    },
    {
        id:6,
        image: require("../images/healthtips/pic6.jpeg")
    },
    {
        id:7,
        image: require("../images/healthtips/pic7.jpeg")
    },
    {
        id:8,
        image: require("../images/healthtips/pic8.jpeg")
    }
]

const HealthTips = () => {
    return(
        <Box
        sx={{
          backgroundColor: "rgb(240 240 240)",
          width: "100%",
        }}
      >
        <Typography
          variant="h3"
          textAlign="center"
          pt={3}
          color="green"
          fontSize="2em"
          fontFamily="inherit"
        >
          <strong>
            Health Tips |{" "}
            <RouterLink to="/gallery" style={{ color: "green" }}>
              Gallery
            </RouterLink>{" "}
          </strong>
        </Typography>
  
        <Grid container spacing={2} sx={{ px: 10, py: 5, margin: "auto" }}>
          {HealthTipsItems.map((item) => (
            <Grid item xs={12} sm={6} lg={3} textAlign="center" pb={"5"}>
              <Card sx={{ maxWidth: 380, height: "100%", borderRadius: "5%" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="100%"
                    width={'380'}
                    image={item.image}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
  
    )
}

export default HealthTips;