import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouterLink } from "react-router-dom";
import logoimage from "../images/logo.png"; // Make sure this path is correct

const pages = [
  "Home",
  "About",
  "Departments",
  "Events",
  "Gallery",
  "Contact",
  "Arogya Sree",
  "Cashless Services",
];

const pageRoutes = {
  Home: "/",
  About: "/about",
  Departments: "/departments",
  Events: "/events",
  Gallery: "/gallery",
  Contact: "/contact",
  "Arogya Sree": "/arogyasree",
  "Cashless Services": "/cashlessservice",
};

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky" sx={{ backgroundColor: "white", color: "black" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <RouterLink to={"/"}>
            <Box
              component="img"
              src={logoimage}
              sx={{
                height: 70,
                display: { xs: "flex", md: "flex" },
                mr: 2,
              }}
              alt="Logo"
            />
          </RouterLink>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none"} }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              flexGrow="1"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page} onClick={handleCloseNavMenu}>
                <RouterLink
                  to={pageRoutes[page]}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography textAlign="center">{page}</Typography>
                </RouterLink>
              </MenuItem>
            ))}
            <MenuItem onClick={handleCloseNavMenu}>
              <a
                href="https://apps.thulasisolutions.in/hms/"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography textAlign="center">Log In</Typography>
              </a>
            </MenuItem>
          </Menu>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "black", display: "block" , float: "right"}}
              >
                <RouterLink
                  to={pageRoutes[page]}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {page}
                </RouterLink>
              </Button>
            ))}
            <a href="https://apps.thulasisolutions.in/hms/" target="_blank" rel="noreferrer">
              <Button
                variant="contained"
                color="success"
                sx={{ my: 2, color: 'white', marginLeft: "auto" }}
              >
                Log In
              </Button>
            </a>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
