import { Box } from "@mui/system";
import { Typography, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

const EventsItem = [
  {
    id: 1,
    eventname: "Medical Camp",
    eventimage: require("../images/event1.jpg"),
  },
  {
    id: 2,
    eventname: "Orientation Program",
    eventimage: require("../images/event2.jpg"),
  },
  {
    id: 3,
    eventname: "ENT Camp",
    eventimage: require("../images/event3.jpg"),
  },
  {
    id: 4,
    eventname: "Helping Hands",
    eventimage: require("../images/event4.jpg"),
  },
  {
    id: 5,
    eventname: "Doctors Day Celebrations",
    eventimage: require("../images/event5.jpg"),
  },
  {
    id: 6,
    eventname: "Health Checkup Camp",
    eventimage: require("../images/event6.jpg"),
  },
];

const Events = () => {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(240 240 240)",
        width: "100%",
      }}
    >
      <Typography
        variant="h3"
        textAlign="center"
        pt={3}
        color="green"
        fontSize="2em"
        fontFamily="inherit"
      >
        <strong>Events</strong>
      </Typography>

      <Grid container spacing={2} sx={{ px: 10, py: 5, marginLeft: 0 }}>
        {EventsItem.map((event) => (
          <Grid item xs={12} sm={6} lg={4} textAlign="center">
            <Card sx={{ maxWidth: 400, height: "100%", borderRadius: '5%' }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="200"
                  image={event.eventimage}
                  alt={event.eventname}
                  // sx={{ width: "8rem", pt: "1rem", margin: "auto" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    color="green"
                    wordWrap="break-word"
                    fontSize="1.4rem"
                  >
                    {event.eventname}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Events;
