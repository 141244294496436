import { Container, Box, Grid, Typography, Button, Link } from "@mui/material";

const Emergency = () => {
  return (
    <Box sx={{ backgroundColor: "#E5F9DB", py: "2.5em" }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box sx={{ px: 3 }}>
              <Typography variant="h3" mb={1} sx={{ fontSize: "20px" }}>
                <Link href="tel:+919000808198" color="#333" underline="none">
                  <strong>+91 9000808198</strong>
                </Link>
              </Typography>
              <Typography
                variant="h2"
                fontWeight="bold"
                mb={2}
                sx={{ color: "green", lineHeight: "inherit", fontSize: '3.4em' }}
              >
                Need An Emergency Help?{" "}
                <span style={{ color: "#ff5733", fontSize: '0.9em' }}>Call Us!</span>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href="tel:+919000808198"
              >
                Call Us
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                px: 3,
                textAlign: "left",
                color: "#757575",
                fontFamily: "'Poppins', sans-serif",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "justify",
                  margin: "0 0 10px",
                  lineHeight: "1.3",
                  textIndent: "2em",
                }}
              >
                In medical emergencies, every second counts. Our hospital is
                devoted to delivering immediate, professional care to patients
                in critical situations. Our highly trained staff is available
                24/7, ensuring top-quality emergency services for our community.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1.2rem",
                  textAlign: "justify",
                  margin: "0 0 10px",
                  lineHeight: "1.3",
                  textIndent: "2em",
                }}
              >
                Beyond our sophisticated healthcare amenities, our hospital also
                ensures flawless integration with various departments and
                specialists. This guarantees a holistic, customized care plan
                for every patient. Regardless of the severity of your health
                issue, ranging from a small injury to a major life-threatening
                condition, you can rely on our committed emergency staff to
                deliver prompt, professional treatment precisely when you
                require it the most.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Emergency;
