import React from "react";
// import HeaderNavbar from "../Headernavbar";
import { Container, Grid, Button, Typography } from "@mui/material";
import OfflinePinRoundedIcon from "@mui/icons-material/OfflinePinRounded";
import aboutimage from "../../images/hos.jpg";
import "./about.css";
const About = () => {
  return (
    <div style={{paddingBottom: '1rem'}}>
      {/* <HeaderNavbar /> */}
      <Container >
        <Typography
          variant="h5"
          style={{ margin: "40px", textAlign: "center" , fontSize: "2rem" }}
        >
          <strong>About Us</strong>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <div className="about-info">
              <h2
                className="wow fadeInUp animated"
                data-wow-delay="0.8s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.8s",
                  animationName: "fadeInUp",
                  textAlign: "justify",
                  paddingBottom: "10px",
                  fontSize: "1.5em",
                }}
              >
                24/7 EMERGENCY, GENERAL, ORTHOPEDIC, TRAUMA CARE MULTY
                SPECIALITY HOSPITAL IN CHIRALA
              </h2>
              <div
                className="wow fadeInUp animated"
                data-wow-delay="0.8s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.8s",
                  animationName: "fadeInUp",
                }}
              >
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "1.1em",
                    color: "#757575",
                    margin: "0 0 10px",
                  }}
                >
                  &ensp; &ensp; Dedicated and committed doctors in Chirala
                  provide high-standard emergency medical care services, backed
                  by well-developed management systems and processes. Our focus
                  is on patient-centered care, utilizing the best technology to
                  ensure faster and more efficient treatment for our patients.
                  We also offer cashless services under the{" "}
                  <strong>Dr. YSR Arogya Sri Scheme.</strong>
                </p>
                <ul className="icon-list" style={{ fontSize: "1.5rem" ,paddingLeft: "25px"}}>
                  <li className="icon-list-item">
                    <OfflinePinRoundedIcon style={{ color: "#5cb85c", fontSize: "30px", paddingTop: "10px" }} />
                    <span>
                      A new generation of doctors is being trained to the
                      highest level of competence, under the guidance of
                      experienced professionals.
                    </span>
                  </li>
                  <li className="icon-list-item">
                    <OfflinePinRoundedIcon style={{ color: "#5cb85c", fontSize: "30px", paddingTop: "10px" }} />
                    <span>
                      We have a strong desire to alleviate others' suffering.
                    </span>
                  </li>
                  <li className="icon-list-item">
                    <OfflinePinRoundedIcon style={{ color: "#5cb85c", fontSize: "30px", paddingTop: "10px" }} />
                    <span>
                      Around Chirala, our innovative approach improves each
                      patient's quality of life.
                    </span>
                  </li>
                </ul>
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    target="_blank"
                    color="primary"
                    href="https://www.facebook.com/people/Sri-Kamakshi-Care-Hospital/100065441741454/"
                  >
                    Get In Touch
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="about-info">
              <div
                className="wow fadeInUp animated"
                data-wow-delay="0.8s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.8s",
                  animationName: "fadeInUp",
                }}
              >
                <div className="about-info">
                  <div
                    className="wow fadeInUp animated"
                    data-wow-delay="0.8s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.8s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <img
                      src={aboutimage}
                      // className="img-responsive"
                      alt=""
                      style={{
                        borderRadius: "6%",
                        paddingLeft: "3em",
                        paddingTop: "6em",
                        paddingRight: "3em",
                        maxWidth: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default About;
