

const DepartmentSpecification = [
    {
        key : 1,
        pageName:"GeneralMedicine",
        Image : require('../../images/departmentImages/general-medicine.png'),
        departmentName : "General Medicine",
    },
    {
        key : 2,
        pageName: "CriticalCare",
        Image : require('../../images/departmentImages/criticalcare.png'),
        departmentName : "Critical Care", 
    },
    {
        key : 3,
        pageName: "Orthopedic",
        Image : require('../../images/departmentImages/orthopedic.png'),
        departmentName : "Orthopedic", 
    },
    {
        key : 4,
        pageName: "Physiotherapy",
        Image : require('../../images/departmentImages/physiotheraphy.png'),
        departmentName : "Physiotherapy", 
    },
    {
        key : 5,
        pageName: "Urology",
        Image : require('../../images/departmentImages/urology2.png'),
        departmentName : "Urology", 
    },
    {
        key : 6,
        pageName: "Ayurvedic",
        Image : require('../../images/departmentImages/ayurvedic.png'),
        departmentName : "Ayurvedic", 
    },
    {
        key : 7,
        pageName: "opthomology",
        Image : require('../../images/departmentImages/eye.png'),
        departmentName : "opthomology", 
    },
    {
        key : 8,
        pageName: "Cardiology",
        Image : require('../../images/departmentImages/heart.png'),
        departmentName : "Cardiology", 
    },
    {
        key : 9,
        pageName: "Neurology",
        Image : require('../../images/departmentImages/neurology.png'),
        departmentName : "Neurology", 
    },
    {
        key : 10,
        pageName: "Gastroenterology",
        Image : require('../../images/departmentImages/gastrology.png'),
        departmentName : "Gastroenterology", 
    },
    {
        key : 11,
        pageName: "Anesthesiology",
        Image : require('../../images/departmentImages/ansthesiology.png'),
        departmentName : "Anesthesiology", 
    },
    {
        key : 12,
        pageName: "Psychiatrist",
        Image : require('../../images/departmentImages/psychiatry.png'),
        departmentName : "Psychiatrist", 
    },
    {
        key : 13,
        pageName: "Dermatology",
        Image : require('../../images/departmentImages/dermatology.png'),
        departmentName : "Dermatology", 
    },
    {
        key : 14,
        pageName: "Nephrology",
        Image : require('../../images/departmentImages/nephrology.jpg'),
        departmentName : "Nephrology", 
    },
    {
        key : 15,
        pageName: "Rheumatology",
        Image : require('../../images/departmentImages/rhematology.jpg'),
        departmentName : "Rheumatology", 
    },
    {
        key : 16,
        pageName: "PlasticSurgeon",
        Image : require('../../images/departmentImages/plasticsurgeon.png'),
        departmentName : "Plastic Surgeon", 
    },
    {
        key : 17,
        pageName: "ENT",
        Image : require('../../images/ent.jpg'),
        departmentName : "ENT", 
    },
    {
        key : 18,
        pageName: "LaproscopicSurgeon",
        Image : require('../../images/departmentImages/laproscope.png'),
        departmentName : "Laproscopic Surgeon", 
    }
];

export default DepartmentSpecification;