const DepartmentDataItems = [
  {
    id: 1,
    deptId: 1,
    pageName: "GeneralMedicine",
    deptname: "General Medicine",
    // doctorImage: require("../../images/doctors/generalmedicine.jpg"),
    // doctorName: "DR. B. TULASI RAM",
    doctors: [
      {
        id: 1,
        doctorName: "DR. B. TULASI RAM",
        doctorImage: require("../../images/doctors/generalmedicine.jpg"),
        qualification: "M.B.B.S., M.D",
        department: "GENERAL MEDICINE",
      },
    ],

    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Manages all aspects of a patient's general health care.",
          "The Department of General Medicine at Sri Kamakshi Care Hospital is one of the best tertiary care centers in Chirala and is staffed by highly qualified doctors. This department provides general OP timings from morning 10.00 to 2.00 PM and evening 6.00 to 9.00 PM. For emergency cases, it is available 24 hours.",
        ],
      },
      {
        label: "specialization",
        content: [
          "The Division of General Medicine provides general and preventive medicine for adults at the Kamakshi Health Center chirala. View all General Medicine clinic locations. In 2016, we saw over 160,000 patients, treating them in all aspects of their health including arthritis, asthma, diabetes, hypertension, gastroenterological diseases, infectious diseases, and heart disease, and also provided preventive care such as immunizations, health counseling, and sports physicals.",
          "A comprehensive facility catering to general and preventive care medicine for adults as well as geriatrics. Internal medicine, also known as general medicine, is a medical specialty that involves treatment, diagnosis and prevention of diseases in adults. Physicians that specialize in internal medicine are known as internists or simply physicians. They are highly skilled in managing patients with multi-system disease processes.",
        ],
      },
    ],
  },
  {
    id: 2,
    deptId: 2,
    pageName: "CriticalCare",
    deptname: "Critical Care",
    // doctorImage: require("../../images/doctors/ansthesiology.jpg"),
    // doctorName: "DR. MUDDANA NAGESWARRAO",
    doctors: [
      {
        id: 1,
        doctorName: "DR. MUDDANA NAGESWARRAO",
        doctorImage: require("../../images/doctors/ansthesiology.jpg"),
        qualification: "M.B.B.S., D.A",
        department: "Anesthesiology And Critical Care Specialist",
      },
      {
        id: 2,
        doctorName: "DR.PAUL",
        doctorImage: require("../../images/doctors/criticalcare2.jpg"),
        qualification: "M.B.B.S., D.A",
        department: "Anesthesiology And Critical Care Specialist",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. M.NAGESWARRAO & DR. PAUL (Anesthesiology And Critical Care) are Available 24 Hours Every Day.",
        ],
      },
      {
        label: "specialization",
        content: [
          "Sri Kamakshi care Hospital’s Department of Anaesthesiology provides a range of anaesthetic, perioperative and pain management services to patients of all ages. There are also several centres under the Department of Anaesthesiology which includes the Pain Management Centre and the Hyperbaric & Diving Medicine Centre.,",
          "The Department of Anaesthesiology covers a wide range of specialty and subspecialty in anaesthesia including: 1) Anaesthetic care in the operating theatres for all types of surgical, diagnostic and therapeutic procedures. 2)Sedation and anaesthesia in angiography, endoscopy and radiology suites as required. 3)Anaesthesia and sedation in the Specialty Centres in the Outram Campus, including National Dental Centre, Singapore National Eye Centre and National Cancer Centre.",
          <>
            <b style={{ paddingLeft: "0" }}>Anesthesiology –</b>
          </>,
          "Shree Kamakshi Care Hospital takes pride in house an excellent anesthesiology department where experienced anthologists are committed to taking care of patients during surgery. We provide state-of-the-art anesthesia services to patients. Anesthesia is a pertinent part of any surgery and used to numb the pain so that the surgical procedure can be smoothly carried out. No surgery can be performed without anesthesia.",
          <>
            <b style={{ paddingLeft: "0" }}>Critical Care –</b>
          </>,
          "The Critical Care Department offers 24×7 care to patients in potentially life-threatening conditions, requiring life support system and other intensive medical interventions The team comprises of trained, efficient and skilled staff in critical care medicine.",
        ],
      },
    ],
  },
  {
    id: 3,
    deptId: 3,
    pageName: "Orthopedic",
    deptname: "Orthopedic",
    doctors: [
      {
        id: 1,
        doctorName: "DR. P. Krishna Chaitanya",
        doctorImage: require("../../images/doctors/ansthesiology.jpg"),
        qualification: "M.B.B.S., MS(Ortho).",
        department: "Orthopedic & Trauma Care",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. P. KRISHNA CHAITANYA (Ortho.) is Available 24 Hours Every Day.",
          <>
            <b style={{ paddingLeft: "0" }}>EVERY DAY 10AM TO 2 PM</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>EVENING 6PM TO 10PM</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>IN EMERGENCY AVAILABLE ANY TIME</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          <>
            <b style={{ paddingLeft: "0" }}>Orthopedic –</b>
          </>,
          "The orthopedic department at Shree Kamakshi Care Hospital is dedicated towards facilitating the finest and top-notch orthopedic afflictions including complex joint replacement, spine surgery, trauma care and sports medicine and joint reconstruction. We help you restore quality of life by focusing on treating patients with musculoskeletal conditions. We are also ably supported by world-class physiotherapy rehabilitation units.",
          <>
            <b style={{ paddingLeft: "0" }}>Trauma Care –</b>
          </>,
          "The well-resourced department of trauma at Shree Kamakshi Care Hospital is managed by the fully efficient and expert doctors who are available 24/7 to control both medical and surgical emergencies ranging from resurgence procedures to complicated trauma cases.",
        ],
      },
    ],
  },
  {
    id: 4,
    deptId: 4,
    pageName: "Physiotherapy",
    deptname: "Physiotherapy",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/3774299.png"),
        doctorName: "DR.A.MYTHILI",
        qualification: "B.P.T.",
        department: "Physiotherapy",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "DR. A.MYTHILI (Physiotherapy) is Available Every Day.",
          <>
            <b style={{ paddingLeft: "0" }}>EVERY DAY 10AM TO 8 PM</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "The department is managed by specially trained and experienced physiotherapists is well equipped to handle all kinds of rehabilitation processes including conditions such as stroke, vestibular injuries, spinal cord injuries, cerebral palsy and post joint replacement rehabilitation. The department’s capabilities include special physiotherapy skills for Musculoskeletal, Cardio-Respiratory, Neurological, Geriatrics & Women’s Health Issues, and Sports & Hand Surgery physiotherapy to provide support for healthy and early mobility. ",
        ],
      },
    ],
  },
  {
    id: 5,
    deptId: 5,
    pageName: "Urology",
    deptname: "Urology",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/urology.jpg"),
        doctorName: "DR. S.KRISHNA RAO",
        qualification: "M.S., M.Ch.",
        department: "Urology",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "DR. S.KRISHNA RAO(Urology) is Available-",
          <>
            <b style={{ paddingLeft: "0" }}>Wednesday – EVENING 4PM TO 8PM</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Saturday – EVENING 4PM TO 8PM</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "The department for Urology at Shree Kamakshi Care Hospital is excellent for those who have diseases of Urinary system including the kidneys, bladder and prostate gland. The Department of Urology provides screening, staging and is the most up to date treatment in the management of urological conditions.",
        ],
      },
    ],
  },
  {
    id: 6,
    deptId: 6,
    pageName: "Ayurvedic",
    deptname: "Ayurvedic",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/ayurvedic.jpg"),
        doctorName: "DR. MURALI KRISHNA",
        qualification: "M.D.",
        department: "Ayurvedic",
      },
    ],
    tabs: [
      {
        label: "About",
        content: [
          "Born to P. Chennakesavacharyulu and Aruna Devi on 9th June, 1990, Dr. Murali Krishna passed out as a Doctor in Ayurveda (B A M S) from Vageswari Ayurvedic Medical College, Karimnagar, Telangana. Later he pursued his MD from KLE Ayurveda Mahavidyalaya, Belgaum, Karnataka and passed out with flying colours and many awards. As a child, he was inspired by his late grand father Acharya, himself a renowned Ayurveda practitioner in Andhra region. In the year 2018, he started his career as a Chief Consultant in Radha Krishna Ayurvedic Hospital as a visionary of Ayurveda and to provide services to every one interested in getting cured through the system of Ayurveda.",
        ],
      },
      {
        label: "Appointment Details",
        content: [
          "DR. MURALI KRISHNA (Ayurvedic) is Available –",
          <>
            <b style={{ paddingLeft: "0" }}>Thursday – 4AM TO 8 PM</b>
          </>,
        ],
      },
      {
        label: "Services And Specialities",
        content: [
          <ul>
            <>
              <li>Heart</li>
            </>
            <>
              <li>Skin</li>
            </>
            <>
              <li>Respiratory</li>
            </>
            <>
              <li>Preventive Medicine</li>
            </>
            <>
              <li>Nerves</li>
            </>
            <>
              <li>Diabetes</li>
            </>
            <>
              <li>Joints and Muscles</li>
            </>
            <>
              <li>Fevers</li>
            </>
            <>
              <li>Stroke/Paralytic Attacks</li>
            </>
            <>
              <li>Digestion</li>
            </>
            <>
              <li>Lifestyle</li>
            </>
            <>
              <li>ENT</li>
            </>
            <>
              <li>Urinary</li>
            </>
            <>
              <li>Male</li>
            </>
            <>
              <li>Female</li>
            </>
          </ul>,
        ],
      },
      {
        label: "Vision",
        content: [
          "To serve everyone using cost effective practices without compromising on the quality and to make the rural regions free of diseases. Conducting more camps on leading healthy life style using Ayurveda, hygyenic practices, prevention of seasonal and viral diseases etc., Bringing more awareness to all sections by posting videos on maintaining good health through the system of Ayurveda. Apart from these, to increase the public acceptance by showing clear evidence of how the ancient system of Ayurveda can heal and also how Ayurveda can be combined with technological advances to heal the patient completely.",
        ],
      },
    ],
  },
  {
    id: 7,
    deptId: 7,
    pageName: "opthomology",
    deptname: "opthomology",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/opthomology.jpeg"),
        doctorName: "DR. T.KUMAR",
        qualification: "D.O.A.",
        department: "Eye Care",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. T.KUMAR (Eye Care) is Availability Every Day.",
          <>
            <b style={{ paddingLeft: "0" }}>EVERY DAY 10AM TO 2 PM</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>EVENING 6PM TO 10PM</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>IN EMERGENCY AVAILABLE ANY TIME</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "The Department of at Shree Kamakshi Care Hospital is dedicated to providing state-of-the-art comprehensive range of medical, surgical and reconstructive services in all aspects of adult and pediatric ophthalmology.",
          "All types of Vision Screening is available: Distance Vision testing, Near vision testing, Color Vision testing, Flurocien staining test also available, Tonometry testing ( Glaucoma diagnosis test),Fungus testing (Direct ophthalmoscopy of Retina ). Also, all types of foreign bodies are removed in our Hospital.",
          "All types of Sunglasses(Cooling glass),Polarized sunglasses , Blue ray glass, Bifocal glass, progressive glass, Single vision glass, Branded frames and branded lens available, Crizal UV lens, Crizal A 2 lens, Seiko lens, Nupolar lens, ZEISS all types of lens, Transitions Adaptive lens, Night driving lens Special designed DRIVEX lens, B&L contact lens and J&J Contact lens.",
        ],
      },
    ],
  },
  {
    id: 8,
    deptId: 8,
    pageName: "Cardiology",
    deptname: "Cardiology",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/cardiologyD.jpeg"),
        doctorName: "DR. KOSARAJU KAMALAKAR",
        qualification: "M.D.,D.M.,",
        department: "Cardiology",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. K.KAMALAKAR (Cardiology) is Available –",
          <>
            <b style={{ paddingLeft: "0" }}>Wednesday – EVENING 4PM TO 8PM</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Saturday – EVENING 4PM TO 8PM</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "Our heart care team at Shree Kamakshi Care Hospital comprises Cardiac Surgeons, Cardiologists, cardiac anesthesiologists, intensivists, and intensive care experts who have adopted a ‘Heart Team’ approach and work in synergy for delivering the best possible outcomes.",
        ],
      },
    ],
  },
  {
    id: 9,
    deptId: 9,
    pageName: "Neurology",
    deptname: "Neurology",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/neurology.jpeg"),
        doctorName: "DR. SARATH CHANDRA",
        qualification: "M.D.,D.M.",
        department: "Neurology",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. SARATH CHANDRA (Neuro.) is Available –",
          <>
            <b style={{ paddingLeft: "0" }}>Sunday – 10AM TO 2 PM</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "Shree Kamakshi Care Hospital has the best neurologist to provide you with the highest standard of surgical and medical treatment for diseases that affect the brain and spinal cord.",
        ],
      },
    ],
  },
  {
    id: 10,
    deptId: 10,
    pageName: "Gastroenterology",
    deptname: "Gastroenterology",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/gastro.jpeg"),
        doctorName: "DR.NAGOOR BASHA",
        qualification: "MD (General Medicine), D.M (Gastro)",
        department: "Gastroenterology",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. NAGOOR BASHA (Gastroenterology) is Available on –",
          <>
            <b style={{ paddingLeft: "0" }}>Sunday – 10AM TO 2 PM</b>
          </>,
        ],
      },
      {
        label: "About",
        content: [
          "Dr. Shaik Nagoor Basha M.D. (General Medicine) and D.M. (Gastroenterology) is exclusive advanced therapeutic Endoscopist and Hepatologist who has dedicated his service in excellence and world class advanced therapeutic endoscopic service to the people of central andhra especially the coastal belt.",
        ],
      },
      {
        label: "specialization",
        content: [
          "Consultant Gastroenterologist & Endodontology’s",
          "Fellow in Advanced Endoscopy",
          "Fellow in Endoscopic Ultrasonography",
        ],
      },
      {
        label: "Services Offered",
        content: [
          <>
            <b style={{ paddingLeft: "0" }}>Liver Biopsy –</b>Liver biopsy is
            the medical test that is done to aid diagnosis of liver disease.
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Endoscopic Ultrasound –</b>{" "}
            Endoscopic Ultrasound (EUS) is a minimally invasive procedure to
            assess digestive and lung diseases.
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>ERCP –</b>ERCP is a procedure that
            allows your doctor to see small tubes inside your body called
            pancreatic.
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Luminal Metal Stents –</b> These are
            fully covered metal stents designed for EUS-guided transluminal
            interventions.
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Gastric Balloon –</b>A gastric
            balloon is an inflatable medical device temporarily placed into the
            stomach to reduce weight.
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Endoscopy –</b>An endoscope is a
            small instrument that is inserted into the body through a tiny
            opening, such as mouth.
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Colonoscopy –</b> A colonoscopy is
            an exam used to detect changes or abnormalities in the large
            intestine and rectum.
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Fibro Scan –</b> FibroTest also
            known as FibroSure in the US which has the same prognostic value as
            a liver biopsy.
          </>,
        ],
      },
    ],
  },
  {
    id: 11,
    deptId: 11,
    pageName: "Anesthesiology",
    deptname: "Anesthesiology",
    doctors: [
      {
        id: 1,
        doctorName: "DR. MUDDANA NAGESWARRAO",
        doctorImage: require("../../images/doctors/ansthesiology.jpg"),
        qualification: "M.B.B.S., D.A",
        department: "Anesthesiology And Critical Care Specialist",
      },
      {
        id: 2,
        doctorName: "DR.PAUL",
        doctorImage: require("../../images/doctors/criticalcare2.jpg"),
        qualification: "M.B.B.S., D.A",
        department: "Anesthesiology And Critical Care Specialist",
      },
    ],

    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. M.NAGESWARRAO & DR. PAUL (Anesthesiology And Critical Care) are Available 24 Hours Every Day.",
        ],
      },
      {
        label: "specialization",
        content: [
          "Sri Kamakshi care Hospital’s Department of Anaesthesiology provides a range of anaesthetic, perioperative and pain management services to patients of all ages. There are also several centres under the Department of Anaesthesiology which includes the Pain Management Centre and the Hyperbaric & Diving Medicine Centre.,",
          "The Department of Anaesthesiology covers a wide range of specialty and subspecialty in anaesthesia including: 1) Anaesthetic care in the operating theatres for all types of surgical, diagnostic and therapeutic procedures. 2)Sedation and anaesthesia in angiography, endoscopy and radiology suites as required. 3)Anaesthesia and sedation in the Specialty Centres in the Outram Campus, including National Dental Centre, Singapore National Eye Centre and National Cancer Centre.",
          <>
            <b style={{ paddingLeft: "0" }}>Anesthesiology –</b>
          </>,
          "Shree Kamakshi Care Hospital takes pride in house an excellent anesthesiology department where experienced anthologists are committed to taking care of patients during surgery. We provide state-of-the-art anesthesia services to patients. Anesthesia is a pertinent part of any surgery and used to numb the pain so that the surgical procedure can be smoothly carried out. No surgery can be performed without anesthesia.",
          <>
            <b style={{ paddingLeft: "0" }}>Critical Care –</b>
          </>,
          "The Critical Care Department offers 24×7 care to patients in potentially life-threatening conditions, requiring life support system and other intensive medical interventions The team comprises of trained, efficient and skilled staff in critical care medicine.",
        ],
      },
    ],
  },
  {
    id: 12,
    deptId: 12,
    pageName: "Psychiatrist",
    deptname: "Psychiatrist",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/3774299.png"),
        doctorName: "DR. P.SANDEEP",
        qualification: "M.D",
        department: "Psychiatrist",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. P.SANDEEP (Psychiatrist) is Available 24 Hours Every Day.",
          <>
            <b style={{ paddingLeft: "0" }}>EVERY DAY 12AM TO 3 PM</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "Stress is one of the foremost reasons for mental disorders and is mainly caused by a disturbance in biological functions or due to minor problems in home, social environment, or work. A team of expert and experienced mental health professionals are dedicated to maintaining a good standard of practice and care. The Department of Psychiatric Medicine at Shree Kamakshi Care Hospital fully equipped to address the mental health needs of adults and children.",
        ],
      },
    ],
  },
  {
    id: 13,
    deptId: 13,
    pageName: "Dermatology",
    deptname: "Dermatology",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/3774299.png"),
        doctorName: "DR. D.AMAR",
        qualification: "M.D.",
        department: "Dermatology",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. D.AMAR (Dermatology) is Available –",
          <>
            <b style={{ paddingLeft: "0" }}>Sunday – 10AM TO 3 PM</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "At Shree Kamakshi Care Hospital, the department of Dermatology provides the best skin treatment services using the latest technologies. focusing on evidence-based care following international protocols for quality and safety of patients. Shree Kamakshi Crae Hospital has best expertise to treat a wide spectrum of conditions ranging from common skin problems like acne, eczemas, psoriasis, vitiligo, leprosy, fungal, viral and common bacterial conditions ,nail disorders, common hair disorders like baldness, hair loss, all kinds of allergies to skin cancers.",
        ],
      },
    ],
  },
  {
    id: 14,
    deptId: 14,
    pageName: "Nephrology",
    deptname: "Nephrology",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/nephrology.jpg"),
        doctorName: "DR. P. PAVAN KUMAR",
        qualification: "M.D., D.M.",
        department: "Nephrology",
      },
    ],
    tabs: [
      {
        label: "About",
        content: [
          <>
            <b style={{ paddingLeft: "0" }}>Education:</b>
          </>,
          "Completed MBBS from PES Medical College, Kuppam.",
          "Completed MD (General Medicine) from Vydehi Medical College, Bangalore.",
          "Completed DM (Nephrology) from PSG Institute of Medical Sciences, Coimbatore.",
          <>
            <b style={{ paddingLeft: "0" }}>Professional experience:</b>
          </>,
          "Medical Officer at Community Health Center, Amaravathi.",
          "Intensivist & CMO at Health hospital, Tenali",
          "Consultant Physician at Kamakshi Care Hospital, Chirala.",
          "Consultant Nephrologist at Abhirami Hospital, Coimbatore.",
        ],
      },
      {
        label: "appointmentdetails",
        content: "NA",
      },
      {
        label: "specialization",
        content: [
          <>
            <b style={{ paddingLeft: "0" }}>Areas of Expertise:</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Clinical Nephrology: </b>Experienced
            in handling
          </>,
          <ol>
            <li>Both acute and chronic Kidney diseases</li>
            <li>Nephrotic syndrome (adult & pediatric)</li>
            <li>Glomerulonephritis</li>
            <li>Infections of kidney and urinary tract</li>
            <li>Secondary/young onset hypertension</li>
            <li>Diabetic kidney diseases</li>
            <li>Congenital diseases of kidney & urinary trac</li>
          </ol>,
          <>
            <b style={{ paddingLeft: "0" }}>Critical care Nephrology:</b>
          </>,
          "Hemodialysis / Hemodiafiltration",
          "SLED",
          "CRRT (CVVHF / CVVHD / CVVHDF / SCUF)",
          "Hemoperfusion for poisoning and drug overdose",
          "Plasmapheresis / Plasma exchange (For renal & non renal indications)",
          <>
            <b style={{ paddingLeft: "0" }}>Kidney transplantation:</b>
          </>,
          "Has been a part of more than 200 transplants.",
          "Skilled in both Live and Cadaver kidney transplantation",
          "Skilled in high risk transplants like ABO Incompatible transplants",
          "Skilled in handling transplant rejections, post transplant infections",
          <>
            <b style={{ paddingLeft: "0" }}>Interventional Nephrology:</b>
          </>,
          "Temporary dialysis catheter placements (>7500)",
          "Permcath / Tunnelled dialysis catheter placements (>200)",
          "Percutaneous CAPD catheter placement (20-30)",
          "Kidney biopsies (>1000)",
        ],
      },
    ],
  },
  {
    id: 15,
    deptId: 15,
    pageName: "Rheumatology",
    deptname: "Rheumatology",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/3774299.png"),
        doctorName: "DR. PRATYUSHA RAJAVARAPU",
        qualification: "M.D, D.M",
        department: "Rheumatology",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. PRATYUSHA RAJAVARAPU (Rheumatology.) is Available –",
          <>
            <b style={{ paddingLeft: "0" }}>Every 1st,3rd Thursday</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "Sri Kamakshi Care Hospital has the best Rheumatologist to provide you with the highest standard of surgical and medical treatment.",
        ],
      },
    ],
  },
  {
    id: 16,
    deptId: 16,
    pageName: "PlasticSurgeon",
    deptname: "Plastic Surgeon",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/plasticsurgeon.jpg"),
        doctorName: "Dr .SUMITHRA SANKAR",
        qualification: "M.Ch, FICS(USA)",
        department: "PlasticSurgeon",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr .SUMITHRA SANKAR (Plastic Surgery) is Available –",
          <>
            <b style={{ paddingLeft: "0" }}>Every 3rd Sunday – 11AM TO 2 PM</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          <ul>
            <li>
              Visiting Scholarship with Chang Gung Memorial Hospital, Linkou
              Taiwan, in Craniofacial & Microsurgery between April 10th to 28th
              2007
            </li>
            <li>
              Fellowship with Centro Medico Tenon, Barcelona, Spain, in
              Aesthetic Surgery between May 2nd to July 4th 2004
            </li>
            <li>
              Fellowship with University of Wisconsin Hospital, Madison,USA in
              Plastic, Reconstructive & Cosmetic Surgery between June 21st to
              August 24th 2007
            </li>
            <li>
              Clinical Observer with Manhattan Eye Ear Throat Hospital (MEETH)
              in Cosmetic Surgery USA between August 1st to 15th 2007
            </li>
            <li>
              Fellowship with Ganga Medical Centre and Hospital Private
              Limited,Coimbatore in Hand & Microsurgery between January 1st to
              February 28th 2006
            </li>
            <li>
              Hands on Course in Microsurgery with Ganga Microsurgical Training
              Institute,Coimbatore between May 17th to 21st 2004
            </li>
            <li>
              Hands on Training programme in Endoscopic Skill Development
              Workshop with Ethicon Institute of Surgical Education, Mumbai on
              January 09 2007
            </li>
            <li>Working in Apollo Hospitals since last Assignment.</li>
          </ul>,
        ],
      },
    ],
  },
  {
    id: 17,
    deptId: 17,
    pageName: "ENT",
    deptname: "ENT",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/ent.jpg"),
        doctorName: "DR. SK.Ajaad",
        qualification: "M.B.B.S.,MS(ENT)",
        department: "ENT",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "DR. SK.Ajaad is Available-",
          <>
            <b style={{ paddingLeft: "0" }}>Wednesday – EVENING 4PM TO 8PM</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>Saturday – EVENING 4PM TO 8PM</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "The department for ENT at Shree Kamakshi Care Hospital is excellent for those who have diseases of Ear, Nose, and Throat.",
        ],
      },
    ],
  },
  {
    id: 18,
    deptId: 18,
    pageName: "LaproscopicSurgeon",
    deptname: "Laproscopic Surgeon",
    doctors: [
      {
        id: 1,
        doctorImage: require("../../images/doctors/laproscopic.jpg"),
        doctorName: "DR. G.LAKSHMINARAYANA",
        qualification: "M.S.,FMAS.",
        department: "Laproscopic Surgeon",
      },
    ],
    tabs: [
      {
        label: "appointmentdetails",
        content: [
          "Dr. G.LAKSHMINARAYANA (General Laparoscope Surgery) is Available 24 Hours Every Day.",
          <>
            <b style={{ paddingLeft: "0" }}>EVERY DAY 10AM TO 2 PM</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>EVENING 6PM TO 10PM</b>
          </>,
          <>
            <b style={{ paddingLeft: "0" }}>IN EMERGENCY AVAILABLE ANY TIME</b>
          </>,
        ],
      },
      {
        label: "specialization",
        content: [
          "At Sri Kamakshi Care Hospital, the Laparoscopic Surgery Department is well equipped with all the advanced technology and excellent infrastructure, and is supported by a skilled team of surgeons, trained technical staff and nurses. Patients receive accurate diagnosis and assessment, latest treatment techniques along with medications, clinical post-operative care and regular follow-ups especially until complete recovery. The team of surgeons are experts in performing a variety of laparoscopic surgeries with great precision, and offering quality services and utmost care.",
        ],
      },
    ],
  },
];

export default DepartmentDataItems;
