import { Container, Typography, Grid, Paper, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DepartmentSpecification from "./departmentspecification";
// import DepartmentData from "./dapartmentdata";

const Departments = () => {
  const navigate = useNavigate();

  const handleCardClick = (departmentId) => {
    navigate(`/departmentdata/${departmentId}`);
  };
  return (
    <Box sx={{ backgroundColor: "#f0f0f0", py: "1.5em" }}>
      <Container>
        <Typography
          variant="h5"
          sx={{ mb: "1em", textAlign: "center", fontWeight: "bold" }}
        >
          Departments
        </Typography>

        <Grid container spacing={3}>
          {DepartmentSpecification.map((department) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={2}
              key={department.key}
              sx={{
                display: "flex",
                pb: "13px",
              }}
            >
              <Paper
                onClick={() => handleCardClick(department.pageName)}

                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  p: 2.5,
                  textAlign: "center",
                  width: "100%", // Make sure Paper takes up the full width of the grid item
                  borderRadius: "15px",
                  "&:hover": {
                    backgroundColor: "#00a0b2",
                  },
                }}
              >
                <Box
                  component="img"
                  src={department.Image}
                  alt={department.departmentName}
                  sx={{
                    width: "2.5em",
                    height: "2.5em",
                    mb: 1,
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ wordWrap: "break-word", fontSize: "1em" }}
                >
                  {department.departmentName}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Departments;