import React from 'react';

const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe
        title="Unique Title for Accessibility"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d290.2008785982418!2d80.35920261522301!3d15.829056167323412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4a441924995d85%3A0x328c14de7d3c1e1a!2sSri%20Kamakshi%20Care%20Hospital!5e1!3m2!1sen!2sin!4v1683023255598!5m2!1sen!2sin"
        width="100%"
        height="450"
        frameBorder="0"
        style={{ border: 0, paddingTop: "3rem" , paddingBottom: "3rem", paddingLeft:"2rem" , paddingRight: "2rem"}}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  );
};

export default AddressMap;
