import { Outlet } from "react-router-dom";
// import Header from "./header";
import Footer from "./footer";
// import Topbar from "./topbar";
import ResponsiveAppBar from "./appbar";
// import ScrollToTop from "./ScrollToTop";
//import on Wrapper
import ScrollTop from "./scrollTop";
function RootLayout() {
  return (
    <>
  <ScrollTop />
    {/* <Topbar /> */}
    <ResponsiveAppBar />
      <Outlet />
      <Footer />
    </>
  );
}
export default RootLayout;
