import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import About from "./components/about/about";
import Departments from "./components/department/department";
import Events from "./components/events";
import Gallery from "./components/gallery";
import Contact from "./components/contact";
import Arogyasree from "./components/arogyasree";
import CashlessService from "./components/cashlessservice";
import RootLayout from "./components/RouterLayout";
import DepartmentName from "./components/appoinment";

import Header from "./components/header";
import DepartmentData from "./components/department/departmentdata";
import HospitalGallery from "./components/hospitalgallery";
import EventGallery from "./components/eventsgallery";
import NewsCoverage from "./components/newscoverage";
import HealthTips from "./components/healthtips";
import VideoGallery from "./components/vediogallery";
import Modal from "./components/Modal";
// import ScrollUpButton from "react-scroll-up-button";

// Menu Route list
const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/", element: <Header /> },
      { path: "/About", element: <About /> },
      { path: "/Contact", element: <Contact /> },
      { path: "/about", element: <About Us /> },
      { path: "/departments", element: <Departments /> },
      { path: "/events", element: <Events /> },
      { path: "/gallery", element: <Gallery /> },
      { path: "/contact", element: <Contact /> },
      { path: "/arogyasree", element: <Arogyasree /> },
      { path: "/cashlessservice", element: <CashlessService /> },
      { path: "/departmentdata/:departmentId", element: <DepartmentData /> },
      { path: "/DepartmentName", element: <DepartmentName /> },
      { path: "/gallery/hospitalgallery", element: <HospitalGallery /> },
      { path: "/gallery/eventsgallery", element: <EventGallery /> },
      { path: "/gallery/newscoverage", element: <NewsCoverage /> },
      { path: "/gallery/healthtips", element: <HealthTips /> },
      { path: "/gallery/vediogallery", element: <VideoGallery /> },
    ],
  },
]);

function App() {
  const [showModal, setShowModal] = useState(false);

  const getCurrentDate = () => {
    const date = new Date();
    let day = String(date.getDate()).padStart(2, "0");
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    if (getCurrentDate() === "15-08-2024" || getCurrentDate()==="16-08-2024") {
      setShowModal(true);
    }
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div>
      {/* Modal Display Logic */}
      {showModal && <Modal onClose={closeModal} />}
      <div>
        <RouterProvider router={router} />
        {/* <ScrollUpButton AnimationDuration={500}/> */}
      </div>
    </div>
  );
}
export default App;
