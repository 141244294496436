import { Grid, Box, Typography, Container } from "@mui/material";
import cashlessserviceImage from "../images/insurence2.jpg.png";

const CashlessService = () => {
  return (
    <Box sx={{ backgroundColor: "#f0f0f0" }}>
      <Container>
        <Grid
          container
          spacing={3}
          sx={{ pt: 8, pb: 8, backgroundColor: "#f0f0f0" }}
        >
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={cashlessserviceImage}
              alt="cashlessservice image"
              sx={{
                width: "100%",
                borderRadius: 5,
                height: { xs: "15em", md: "20em" },
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ px: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: "left",
                  color: "green",
                  pl: 8,
                  pb: 3,
                  // fontWeight: "bold",
                }}
              >
                WHAT WE’RE OFFERING
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  textAlign: "left",
                  pl: 8,
                  pb: 3,
                  fontWeight: "600",
                  fontSize: "2em",
                }}
              >
                Cashless Service
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  // textAlign: "left",
                  margin: "0 0 10px",
                  lineHeight: "1.3",
                  textIndent: "2em",
                  fontFamily: "'Poppins',sans-serif",
                  fontWeight: 400,
                  color: "#757575",
                  fontSize: "1.2em",
                  pl: 8,
                  textAlign: "justify",
                }}
              >
                Cashless service is available at Sri Kamakshi Care Hospital.
                Insured members associated with our Memorandum of Understanding
                (MOU) can visit our hospital and receive medical treatment
                without paying out-of-pocket at the hospital. This includes room
                rent, admission charges, treatment costs, ambulance costs, as
                well as doctor/specialist fees.
              </Typography>{" "}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CashlessService;
