import { Box, Typography, Grid, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import DepartmentDataItems from "./departmentdataitems";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link as RouterLink } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";

const DepartmentData = () => {
  const { departmentId } = useParams();

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Function to render the content for each tab
  const renderTabContent = (content) => {
    if (Array.isArray(content)) {
      return content.map((item, index) => (
        <Typography key={index} paragraph>
          {item}
        </Typography>
      ));
    }
    return <Typography paragraph>{content}</Typography>;
  };

  return (
    <Box sx={{ borderBottom: "1px solid black" }}>
      <Typography
        variant="h3"
        textAlign="center"
        color="#19A7CE"
        fontSize="1em"
        fontFamily="inherit"
        pt={"1.5rem"}
      >
        <strong>
          <RouterLink to="/" style={{ color: "green" }}>
            Home{" "}
          </RouterLink>
          |{" "}
          <RouterLink to="/departments" style={{ color: "green" }}>
            Department
          </RouterLink>{" "}
        </strong>

        {/* Home | Department */}
      </Typography>
      {/* <Grid container spacing={1} sx={{ px: 5, py: 1,  }} border={'10px solid green '} > */}
      {DepartmentDataItems.map(
        (item) =>
          item.pageName === departmentId && (
            <Grid item xs={12} md={6} key={item.id}>
              <Typography
                variant="h4"
                textAlign="center"
                color={"green"}
                pt={"1rem"}
              >
                {item.deptname}
              </Typography>
              <Grid
                container
                spacing={1}
                px={2}
                pt={5}
                sx={{ display: "flex" }}
              >
                <Grid
                  item
                  sx={{ width: "50%", display: "flex", flexWrap: "wrap" }}
                  p={5}
                >
                  {item.doctors.map((doctors, index) => (
                    // doctor grid
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      px={2}
                      pt={2}
                      sx={{
                        display: "flex",
                        border: "2px solid #ccc",
                        borderRadius: "2%",
                        // flexDirection: {
                        //   xs: 'column',
                        //   sm: 'column',
                        //   md: 'column',
                        //   lg: 'column'
                        // },
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {doctors.doctorImage && (
                          <img
                            src={doctors.doctorImage}
                            alt={`Doctor ${item.doctorName}`}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              p: "5",
                              height: "14em",
                              paddingBottom: "5px",
                              width: "12em",
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={12} pl={3}>
                        <Typography variant="h6" gutterBottom>
                          <strong>{doctors.doctorName}</strong>
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {doctors.qualification}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          {doctors.department}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          gutterBottom
                        >
                          <strong>EVERY DAY 10 AM TO 02 PM</strong>
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          gutterBottom
                        >
                          <strong>EVENING 06 PM TO 10 PM</strong>
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          gutterBottom
                        >
                          <strong>IN EMERGENCY AVAILABLE ANY TIME</strong>
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 2,
                          }}
                        >
                          <CheckCircleIcon sx={{ color: "green" }} />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ ml: 1 }}
                          >
                            Medical Registration Verified
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                {/* contact page grid                   */}
                <Grid item p={6}>
                  <Grid
                    item
                    lg={10}
                    md={12}
                    sm={12}
                    px={5}
                    pt={4}
                    sx={{
                      border: "2px solid #ccc",

                      borderRadius: "2%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box>
                      <Typography variant="h6" gutterBottom>
                        CONTACT HOSPITAL TO BOOK APPOINTMENT
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        SRI KAMAKSHI CARE HOSPITAL
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        Chirala. Prakasham, Andhra Pradesh
                      </Typography>
                    </Box>
                    <Box sx={{ pt: "2em", pb: "1em", textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ height: "3em" }}
                        startIcon={<PhoneIcon />}
                        href="tel:+919000808198"
                      >
                        Call me!
                      </Button>
                    </Box>
                    <Box sx={{ pb: "2em", textAlign: "center" }}>
                      <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        sx={{ height: "3em" }}
                        startIcon={<WhatsAppIcon />}
                        href="https://wa.me/+917897890737"
                      >
                        Whatsapp Us
                      </Button>
                    </Box>
                  </Grid>{" "}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={10}
                md={10}
                lg={10}
                sx={{
                  pt: "5",
                  textAlign: "justify",
                  fontFamily: "Poppins",
                  sans: "serif",
                  pl: "20px",
                }}
              >
                <Box sx={{ width: "80%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        {item.tabs.map((tab, index) => (
                          <Tab
                            label={tab.label}
                            value={String(index + 1)}
                            key={index}
                          />
                        ))}
                      </TabList>
                    </Box>
                    {item.tabs.map((tab, index) => (
                      <TabPanel value={String(index + 1)} key={index}>
                        {renderTabContent(tab.content)}
                      </TabPanel>
                    ))}
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          )
      )}
      {/* </Grid>{" "} */}
    </Box>
  );
};

export default DepartmentData;
